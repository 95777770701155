$colorPrimary200: #093f83;
$colorPrimary400: #092a83;
$colorPrimary: #093f83;
$colorPrimary700: #090c83;
$colorSecondary200: #41d6fa;
$colorSecondary400: #41c6fa;
$colorSecondary: #41b7fa;
$colorSecondary700: #41a8fa;
$colorWarning400: #ffcf74;
$colorWarning: #ffbb38;
$colorWarning600: #b38327;
$colorSuccess400: #5dc389;
$colorSuccess: #18a957;
$colorError400: #f94472;
$colorError: #f8285a;
$grey-box: #dddddd;
$gray: #8b8a86;
$grey: #eaedf1;
$grey300: #dbdfe9;
$grey400: #f1f1f4;
$grey600: #757575;
$grey700: #616161;
$grey900: #071437;
$colorBlack: #212121;
$colorWhite: #ffffff;
$grayWithOpacity: #e1e1e152;
$authenticationBackground: #e1f2ff99;
