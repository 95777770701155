/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
}

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/scss/variables.scss";

html,
body {
    height: 100%;
    font-size: 13px !important;
    font-weight: 400;
    overflow: hidden;
    background-color: $grey300;
}

* {
    margin: 0;
    padding: 0;
    font-family: Inter, Helvetica, "sans-serif";
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.form-label {
    color: $grey900;
    font-weight: 600 !important;
    font-size: 1.075rem !important;
}

.main {
    width: calc(100vw - 90px);
    overflow: auto;
    margin-left: auto;
    // padding-bottom: 50px;
    position: relative;
}

@media (min-width: 1200px) {
    .container-fluid {
        padding: 0 16px;
    }
}

.gps-action {
    i {
        padding: 0 6px;
        &:first-child {
            padding-left: 0px;
        }
        &:last-child {
            padding-right: 0px;
        }
    }
}

.fa-pen-to-square,
.fa-circle-info {
    color: $colorPrimary;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover,
    &:focus {
        color: $colorPrimary200;
    }
}

.fa-trash-can,
.fa-eye-slash,
.fa-location-dot {
    color: $colorError;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover,
    &:focus {
        color: $colorError400;
    }
}

.fa-eye {
    color: $colorSuccess;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover,
    &:focus {
        color: $colorSuccess400;
    }
}

.fa-list,
.fa-file-alt {
    color: $colorSecondary;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover,
    &:focus {
        color: $colorSecondary200;
    }
}

.active-status {
    background-color: transparent !important;
    color: $colorSuccess;
    padding: 4px 22px;
    border: 2px solid $colorSuccess;
    border-radius: 7px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
}

.de-active-status {
    background-color: transparent !important;
    color: $colorError;
    padding: 4px 12px;
    border: 2px solid $colorError;
    border-radius: 7px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
}

.active-status-mobile {
    color: $colorSuccess !important;
}

.de-active-status-mobile {
    color: $colorError !important;
}

.mat-mdc-snack-bar-container {
    &.success-snackbar {
        --mdc-snackbar-container-color: #4bb543;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.mat-mdc-snack-bar-container {
    &.error-snackbar {
        --mdc-snackbar-container-color: #ed4337;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.mat-mdc-snack-bar-container {
    &.info-snackbar {
        --mdc-snackbar-container-color: #1e92f4;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.mat-mdc-snack-bar-container {
    &.warning-snackbar {
        --mdc-snackbar-container-color: #f44336;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.toolbarForTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    border-top: none;
    background: $grey300 !important;
    padding: 6px;

    .buttonAndLabel {
        display: flex;
        align-items: center;
        position: relative;

        > div {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            gap: 7px;

            button {
                margin-right: 0;
            }

            .popup {
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                /* The actual popup */
                .popuptext {
                    display: none;
                    width: 300px;
                    background-color: $colorWhite;
                    color: $gray;
                    border-radius: 6px;
                    padding: 8px 0;
                    position: absolute;
                    z-index: 3;
                    top: 100%;
                    left: 0%;
                    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    border-radius: 4px;
                    box-shadow:
                        0px 5px 5px -3px rgba(0, 0, 0, 0.2),
                        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
                        0px 3px 14px 2px rgba(0, 0, 0, 0.12);

                    .popup-container {
                        padding: 0 6px;
                    }
                }

                /* Toggle this class - hide and show the popup */
                .show {
                    display: block;
                    -webkit-animation: fadeIn 1s;
                    animation: fadeIn 1s;
                }

                .reset {
                    width: 100%;
                    padding: 8px 0 0 !important;
                    color: $colorBlack !important;
                    font-weight: 600;
                    cursor: pointer;
                    text-transform: uppercase;
                    background-color: transparent !important;
                    border: none;
                    border-top: 1px solid $grey300;
                    outline: none;
                    text-align: center;
                    justify-content: center;

                    &.disabled {
                        color: $gray !important;
                        font-weight: 500;
                    }
                }
            }
        }

        .form-label {
            margin: 0 8px 0 0;
        }

        .form-label.no-margin {
            margin: 0;
        }

        .icon-font {
            font-size: 12px;
        }

        > div > button,
        > div > a > button {
            display: flex;
            align-items: center;
            background-color: $colorPrimary;
            color: $colorWhite;
            margin: 0 0.17rem;
            padding: 0.2rem !important;
            border-radius: 3px;

            &:hover {
                background-color: $colorPrimary200;
            }

            span {
                margin-right: 4px !important;
            }
        }
    }

    .search-control {
        display: block !important;
        padding: 0.55rem 1rem;
        background-color: $colorWhite !important;
        border: 1px solid $grey300 !important;

        div::after,
        div::before {
            border-bottom: none !important;
        }

        input {
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.5;
            color: $grey700;
            appearance: none;
            background-clip: padding-box;
            border-radius: 0.475rem;
            box-shadow: false;
            transition:
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }
    }
}
.table-responsive {
    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #909090;
    }
}
table {
    border-collapse: collapse;
    width: 100%;
    cursor: default;
    display: inline-table;

    thead,
    tbody {
        td,
        th {
            border-bottom: 1px solid $grey300;
            text-align: left;
            padding: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25ch;
            cursor: pointer;
        }
    }

    thead {
        tr:nth-child(even) {
            font-weight: 700;
        }

        .header {
            cursor: pointer;
        }

        span.header {
            margin-right: 8px;
            white-space: nowrap;
        }

        .showOnHover {
            visibility: hidden;
        }

        span.header:hover + .showOnHover {
            visibility: visible;
        }
    }
}

.fa {
    /*margin-left: 8px;
    margin-bottom: 4px;
    margin-top: 4px;*/
    width: 32px;
    padding: 0 8px 0 0;
}

.no-data-found {
    text-align: center !important;
    font-size: 1rem;
    font-weight: 600;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.875rem;
    min-height: 44px;
    flex: 1;

    > div {
        display: flex;
        align-items: center;
    }

    .details {
        margin: 0 2rem 0 1rem;
    }

    .prevNext {
        cursor: pointer;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $grayWithOpacity;
        border: 1px solid;
        border-radius: 18px;
        margin: 0px 6px 0 0;

        .fa-chevron-left,
        .fa-chevron-right {
            color: $colorBlack !important;

            &.disabled {
                color: $gray !important;
            }
        }
    }
}

// .mobileTable {
//     display: none;
// }
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: $colorSecondary !important;
}
.h-100-vh {
    height: 100vh;
}

.authentication-h-100-vh {
    height: 100vh;
}

.authentication-banner {
    height: auto;
    object-fit: contain;
}

.authentication-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $authenticationBackground;

    .app-logo {
        height: 100px;
    }

    .authentication-title {
        font-weight: 700;
    }
}

.w-lg-500px {
    width: 500px !important;
}

.pv-075 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;

    &.crud-action {
        display: flex;
        justify-content: end;
        align-items: center;
    }
}

@media (max-width: 991.98px) {
    .fa {
        margin-left: 24px;
    }
    .toolbarForTable {
        flex-direction: column;
        > div {
            width: 100%;
        }
        .buttonAndLabel {
            justify-content: space-between;
            .form-label {
                margin-bottom: 2px;
            }
        }
        .search-control {
            width: 100%;
            margin-top: 6px;
        }
    }

    .pagination {
        padding: 6px;
        justify-content: center;
        background: white;
        border-radius: 0 0 8px 8px;
        .prevNext {
            margin: 6px 6px 0 0;
        }
    }

    /*.webTable {
        display: none;
    }*/

    .mobileTable {
        //display: block;
        border: 2px solid #dbdfe9;
        border-radius: 8px;
        margin-bottom: 8px;
    }

    .custom-table {
        display: block;
        background: $grayWithOpacity;
        padding-bottom: 12px;
        .no-data-found {
            padding: 16px 0 0;
        }

        .information-card {
            margin: 6px 6px 6px !important;
            padding: 6px !important;
            z-index: 2;
            border-radius: 8px;
            box-shadow: 0 0 6px #a39e9e;

            .field-title {
                padding-right: 6px;
            }

            &:nth-child(1) {
                margin: 12px 6px 6px !important;
            }
        }

        .row {
            .col-sm-6:nth-child(odd) {
                padding-right: 3px !important;
            }
            .col-sm-6:nth-child(even) {
                padding-left: 3px !important;
            }
        }

        .action-card {
            margin: -9px 18px 0px 18px !important;
            padding: 12px 6px 6px 6px !important;
            background: #0a4895;
            text-align: center;
            z-index: 1;
            border-radius: 0 0 8px 8px;
            box-shadow: 0 0 10px #434242;

            .fa {
                color: white !important;
                font-size: 14px;
                padding: 0 2px 4px;
            }
        }
    }

    .authentication-banner {
        height: 250px;
    }

    .authentication-h-100-vh {
        height: calc(100vh - 250px);
    }

    .authentication-form {
        justify-content: flex-start;
    }

    .w-lg-500px {
        width: 100% !important;
        padding: 2rem;
    }
}

@media (max-width: 767.98px) {
    .toolbarForTable {
        .buttonAndLabel {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .pagination {
        padding: 6px;
        justify-content: center;
        border-radius: 0 0 8px 8px;
        .prevNext {
            margin: 6px 6px 0 0;
        }
    }

    .custom-table {
        .row {
            .col-12 {
                padding-right: 3px !important;
                padding-left: 3px !important;
            }
        }
    }

    .w-lg-500px {
        height: -webkit-fill-available;
        width: 100% !important;
        padding: 1rem;
    }

    .pv-075 {
        padding-top: 0 !important;
        padding-bottom: 0.75rem !important;
        &.crud-action {
            display: flex;
            justify-content: start;
            align-items: center;
        }
    }
}
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after, 
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after{
    color: #41b7fa !important; 
}